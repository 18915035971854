<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <vs-row>
      <vs-col v-for="module in this.modules" type="flex" vs-justify="center" vs-align="center" vs-w="4">
        <vs-card>
          <div slot="header">
            <h3>{{ module.moduleName }}</h3>
          </div>
          <div>
            <vs-list>
              <vs-list-item title="Acciones">
                <div class="flex">
                  <vx-tooltip v-for="action in module.actions" :text="action.tooltipText">
                    <vs-button
                      radius size="large" color="dark" type="flat" icon-pack="feather" :icon="action.icon"
                      @click="goToRoute(action.routeSettings)"
                    />
                  </vx-tooltip>
                </div>
              </vs-list-item>
            </vs-list>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>

</template>

<script>
  export default {
    data() {
      return {
        modules: [
          {
            moduleName: "Agencias y Sucursales",
            actions: [
              {tooltipText: "Listar", icon: "icon-list", routeSettings: {name: "stores"}},
              {tooltipText: "Crear", icon: "icon-plus", routeSettings: {name: "create-store"}}
            ]
          },
          {
            moduleName: "Banners y Slides",
            actions: [
              {tooltipText: "Listar", icon: "icon-list", routeSettings: {name: "media-objects"}}
            ]
          },
          {
            moduleName: "Menús",
            actions: [
              {tooltipText: "Listar", icon: "icon-list", routeSettings: {name: "menus"}}
            ]
          },
          {
            moduleName: "Ciudades",
            actions: [
              {tooltipText: "Listar", icon: "icon-list", routeSettings: {name: "cities"}}
            ]
          },
          {
            moduleName: "Documentos",
            actions: [
              {tooltipText: "Listar", icon: "icon-list", routeSettings: {name: "documents"}},
              {tooltipText: "Cargar Documento", icon: "icon-share", routeSettings: {name: "create-document"}},
              {tooltipText: "Categorías y Subcategorías", icon: "icon-tag", routeSettings: {name: "categories"}},
            ]
          },
          {
            moduleName: "Plazas Disponibles",
            actions: [
              {tooltipText: "Listar", icon: "icon-list", routeSettings: {name: "jobs"}},
              {tooltipText: "Crear", icon: "icon-plus", routeSettings: {name: "create-job"}}
            ]
          },
          {
            moduleName: "Productos",
            actions: [
              {tooltipText: "Listar", icon: "icon-list", routeSettings: {name: "products"}},
              {tooltipText: "Crear", icon: "icon-plus", routeSettings: {name: "create-product"}}
            ]
          },
          {
            moduleName: "Promociones",
            actions: [
              {tooltipText: "Listar", icon: "icon-list", routeSettings: {name: "promotions"}},
              {tooltipText: "Crear", icon: "icon-plus", routeSettings: {name: "create-promotion"}}
            ]
          },
          {
            moduleName: "Tutoriales",
            actions: [
              {tooltipText: "Ver", icon: "icon-film", routeSettings: {name: "tutorials"}},
            ]
          },
        ],
      }
    },
    methods: {
      goToRoute(routeSettings) {
        this.$router.push(routeSettings);
      }
    },
  }
</script>

